<template>
  <div :style="{height:windowHeiht+'px',padding:'10px'}">
    <div id="map" ref="map" class="mapCss">
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'

export default {
  data() {
    return {
      windowHeiht: document.documentElement.clientHeight,
      user: [],
      Map: null,

      siteList: [], // 站点列表
      deviceList: [], // 全部设备列表
      notStateNormalNum: 0 // 运行不正常的设备数
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      this.createMap().then(res => {
        this.createMarker()
      })
    },
    createMap() {
      return new Promise((resolve, reject) => {
        const map = new AMap.Map('map', {
          zoom: 12,
          center: [104.063161, 30.592624]
        })
        this.Map = map
        resolve('')
      })
    },
    createMarker() {
      const that = this
      this.siteList = [
        {
          longitude: 104.056718,
          latitude: 30.586518
        }
      ]
      this.siteList.forEach(item => {
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            size: new AMap.Size(40, 50), // 图标大小
            image: require('./components/map.png')
          }),
          position: [item.longitude, item.latitude],
          map: that.Map,
          animation: 'AMAP_ANIMATION_DROP'
        })
        AMap.event.addListener(marker, 'click', () => {
          infoWindow.open(that.Map, marker.getPosition())
        })
        const infoWindow = new AMap.InfoWindow({
          content: `<div style="width:400px;padding:0 20px;">
            地址：四川省成都市高新区府城大道西段399号天府新谷七号楼B座1509
          </div>`,
          offset: new AMap.Pixel(12, -30)
        })
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.mapCss {
  position relative
  height 100% /*写给不支持calc()的浏览器*/
  height -moz-calc(100% - 10px)
  height -webkit-calc(100% - 10px)
  height calc(100% - 10px)
  width 100%
  .fullbutton {
    position absolute
    top 30px
    right 30px
    z-index 99
  }
}
</style>
